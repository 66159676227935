.footer {
    background: #202020;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
    padding: 0.15rem 0.1rem 0.1rem;
    display: flex;
    justify-content: space-around;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 540px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    font-size: 12px;
}
.footer_item {
    width: 25%;
    flex: 1;
}
.icon_footer,
.footer img {
    height: 22px;
    margin-bottom: 2px;
}
.footer a {
    color: #fff;
}
.footer .active a {
    color: #fff;
}
.icon_footer_center {
    align-items: center;
    background: linear-gradient(180deg, #f09c44, #ed5b29);
    border-radius: 50%;
	top: -15px;
    display: flex;
    height: 42px;
    justify-content: center;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 42px;
}
.icon_footer_center img {
    filter: grayscale(100%) brightness(400%);
    font-size: 0;
    height: 30px;
    width: 30px;
}
